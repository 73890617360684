<template>
  <div id="genotype-section">

    <!-- patient genotype -->
    <text-field
      v-if="handleLogicStatement('patientGenotype', 'visibility')"
      v-model="content.patientDiagnosis.patientGenotype"
      label="Patient Genotype"
      name="patientGenotype"
      placeholder="Patient Genotype"
      :content="content"
      :logic="logic"
      :errors="errors"
      @blurEvent="updateData"
    />

    <!-- Date of Genotype Assay -->
    <datepicker
      v-if="handleLogicStatement('dateOfGenotypeAssay', 'visibility')"
      v-model="content.patientDiagnosis.dateOfGenotypeAssay"
      label="Date of Genotype Diagnosis"
      name="dateOfGenotypeAssay"
      placeholder="Enter Date of Genotype Assay"
      :content="content"
      :logic="logic"
      :errors="errors"
      :isDisable="handleLogicStatement('dateOfGenotypeAssay', 'disable')"
      @changeEvent="updateData"
    />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    updateData: {
      type: Function,
      required: true
    },
    errors: {
      type: Object
    },
    logic: {
      type: Object
    },
    updateFieldVal: {
      type: Function
    }
  }
};
</script>

<style lang="scss" scoped>
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(170, 170, 170);
  margin: 1em 0 0.5em 0;
  padding: 0;
}
label {
  font-weight: 500;
  font-size: 13px;
}
.form-group {
  margin-bottom: 10px;
}
</style>
